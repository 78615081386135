<template>
  <div class="">
    <a
      href="ss"
      download
    />
    <b-card
      no-body
      class="mr-1"
      style="height: 100%; overflow-x: hidden;"
    >
      <div>
        <b-button
          variant="primary"
          class="mt-1 ml-1 saj-button"
          @click="viewHistory()"
        >
          {{ $t('History Approved Performances') }}
        </b-button>
      </div>

      <div class="pl-1 pt-1 ">
        <span class="saj-title">
          {{ $t("Search Filter") }}
          <!-- {{ roles }} -->
          <!-- {{ approveList }} -->
        </span>
      </div>
      <div class="row align-items-end px-1 mb-1">
        <b-col
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Category") }}:</label>
          <b-form-select
            v-model="selectedCategory"
            :options="categoryList"
            :value="categoryList"
            size="sm"
            class="saj-text"
          />
        </b-col>
        <!-- if admin -->
        <!-- <template v-if="roles.selectedRole === 1"> -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Subsidiary") }}:</label>
          <v-select
            v-model="selectedSub"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Subsidiary')"
            :options="subList"
            :reduce="sub => sub.value"
            label="text"
            :clearable="false"
            :disabled="selectedCategory === null"
            @input="selectedSub !== null ? getData() : ''"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Work Location") }}:</label>
          <v-select
            v-model="selectedWork"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Work Location')"
            :options="WorkList"
            :reduce="work => work.value"
            label="text"
            :disabled="selectedSub === null"
          />
        </b-col>
        <b-col
          v-if="roles.selectedRole === 1 || roles.selectedRole === 6"
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Department") }}:</label>
          <v-select
            v-model="selectedDepartment"
            class="saj-text select-size-sm"
            style="background: white"
            :placeholder="$t('Choose Department')"
            :options="departmentList"
            :reduce="dept => dept.value"
            label="text"
            :disabled="roles.selectedRole !== 1 ? selectedCategory === null : selectedSub === null"
          />
        </b-col>
        <!-- </template> -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Search Name") }}:</label>
          <b-form-input
            id="icons-search"
            v-model="searchname"
            :placeholder="$t('Search name')"
            size="sm"
            :disabled="selectedCategory === null"
          />
        </b-col>
        <b-col

          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Search Employee Number") }}:</label>
          <b-form-input
            id="icons-search"
            v-model="searchEmpNo"
            :placeholder="$t('Search Employee Number')"
            size="sm"
            :disabled="selectedCategory === null"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 pt-1"
        >
          <label class="saj-text">{{ $t("Approval Status") }}:</label>
          <b-form-select
            v-model="selectedStatus"
            :options="statusList"
            :value="statusList"
            :disabled="roles.isAdmin ? (selectedSub === null) : false"
            size="sm"
            class="saj-text"
          />
        </b-col>
        <div
          v-if="roles.isAdmin"
          class="d-flex mt-1"
        >
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="d-flex justify-content-center"
              style=""
              :disabled="selectedCategory === null || selectedSub === null"
              @click="getSummaryReport('search'), getAllApproveList(), currentPage = 1"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="saj-title d-flex justify-content-center"
              block
              style="
            "
              @click="clearButton()"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
            </b-button>
          </b-col>
        </div>
        <div
          v-else
          class="d-flex mt-1"
        >
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="d-flex justify-content-center"
              :style="roles.isHR ? {'margin-top': '15px'} : {}"
              :disabled="selectedCategory === null"
              @click="getSummaryReport('search'), getAllApproveList(), currentPage = 1"
            >

              <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
            </b-button>
          </b-col>
          <b-col>
            <b-button
              md="4"
              variant="primary"
              class="saj-title d-flex justify-content-center"
              block
              :style="roles.isHR ? {'margin-top': '15px'} : {}"
              @click="clearButton()"
            >
              <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
            </b-button>
          </b-col>
        </div>

      </div>
      <b-overlay
        :show="show"
        rounded="sm"
        class="mt-4"
        style="height: 100%;"
      >

        <template #overlay>
          <div class="d-flex flex-column align-items-center justify-content-center">
            <b-spinner
              variant="primary"
              label="Spinning"
            />
            <p class="mt-1">
              {{ $t('Fetching Data') }}...
            </p>
          </div>

        </template>
        <div
          v-if="showReport"
          class="p-1"
        >
          <b-table
            sticky-header="500px"
            style="max-height:900px;"
            :items="finalData"
            :fields="allColumnField"
            responsive
            bordered
            show-empty
          >

            <template #cell(index)="data">
              <div class="saj-subtitle d-flex justify-content-center">
                {{ currIdx === 1 ? data.index + 1 : (data.index + 1)+((currIdx-1) *perPage) }}
              </div>
              <!-- {{ allColumnField }} -->
            </template>
            <template #empty="">
              <h4 style="text-align: center; font-style: italic">
                {{ $t('There are no records to show') }}
              </h4>
            </template>
            <template #thead-top="">
              <b-tr class="saj-subtitle">
                <!--core-->
                <b-th
                  colspan="6"
                  class=""
                >
                  <!-- asdad -->
                </b-th>
                <b-th
                  class="text-center saj-subtitle"
                  style=""
                  variant="primary"
                  :colspan="krajdCol"
                >
                  {{ $t("KRA/JD") }}
                </b-th>
                <b-th
                  class="text-center saj-subtitle"
                  style=""
                  variant="primary"
                  :colspan="core"
                >
                  {{ $t("Competency Core") }}
                </b-th>
                <b-th
                  class="text-center saj-subtitle"
                  style=""
                  variant="primary"
                  :colspan="behaviour"
                >
                  {{ $t("Competency Behaviour") }}
                </b-th>
                <b-th
                  class="text-center saj-subtitle"
                  style=""
                  variant="primary"
                  :colspan="maxSub"
                >
                  {{ $t("Training") }}
                </b-th>
                <b-th
                  class="saj-subtitle"
                  style=""
                  variant="primary"
                  :colspan="additionalCol"
                />
              </b-tr>
            </template>
            <template #head()="data">
              <span
                class="saj-subtitle"
                style=""
              >{{ $t(data.label) }}</span>
            </template>
            <!-- <template #cell(jd_new)="data">
                elo
              </template> -->
            <!-- <template
                #cell(kra)="data"
              >
                <div
                  v-for="(x, idx) in data.item.kra"
                  :key="idx"
                  class="d-flex align-items-center"
                  style="white-space: nowrap"
                >
                  {{ x.name }} {{ x.score === null ? '' : ':' }} {{ x.score }}
                </div>
              </template> -->
            <template #cell(action)="data">
              <div
                class="d-flex align-items-center justify-content-center"
                style="color: #464e5f;"
              >
                <!-- Executive & Top Management -->
                <feather-icon
                  v-if="roles.selectedRole === 3 || roles.selectedRole === 4"
                  v-b-modal.edit-employee
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style=" width: 1.5rem; height: 1.5rem"
                  class="mr-1"
                  :disabled="data.item.is_submit ? data.item.is_approve : true"
                  :style="data.item.is_submit ? (data.item.is_approve ? 'color: grey;' : 'color: orange;') : 'color: grey;'"

                  @click="tempUser = data.item.user_id, tempPerf = data.item.performance_id, tempSubs = data.item.subsidiary_id"
                />
                <!-- Head of Department -->
                <feather-icon
                  v-if="roles.selectedRole === 5"
                  v-b-modal.edit-employee
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style=" width: 1.5rem; height: 1.5rem"
                  class="mr-1"
                  :disabled="data.item.is_agree ? data.item.is_approve : true"
                  :style="data.item.is_agree ? (data.item.is_approve ? 'color: grey;' : 'color: orange;') : 'color: grey;'"

                  @click="tempUser = data.item.user_id, tempPerf = data.item.performance_id, tempSubs = data.item.subsidiary_id"
                />
                <!-- HR & Admin -->
                <feather-icon
                  v-if="roles.selectedRole === 6 || roles.selectedRole === 1"
                  v-b-modal.edit-employee
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style=" width: 1.5rem; height: 1.5rem"
                  class="mr-1"
                  :disabled="data.item.is_submit ? data.item.is_approve : true"
                  :style="data.item.is_submit ? (data.item.is_approve ? 'color: grey;' : 'color: orange;') : 'color: grey;'"

                  @click="tempUser = data.item.user_id, tempPerf = data.item.performance_id, tempSubs = data.item.subsidiary_id"
                />

                <feather-icon
                  v-if="roles.selectedRole === 3 || roles.selectedRole === 4"
                  v-b-modal.carry-mark
                  v-b-tooltip.hover.bottom="$t('Carry Marks')"
                  class="mr-1"
                  icon="FileTextIcon"
                  style=" width: 1.5rem; height: 1.5rem"
                  :disabled="data.item.is_submit ? data.item.is_agree : true"
                  :style="data.item.is_submit ? (data.item.is_agree ? 'color: grey' : 'color: #00b3ff') : 'color: grey'"
                  @click="performanceId = data.item.performance_id"
                />

                <feather-icon
                  v-if="roles.selectedRole === 3 || roles.selectedRole === 4"
                  v-b-modal.approve-employee
                  v-b-tooltip.hover.bottom="$t('Approve')"
                  icon="CheckSquareIcon"
                  style=" width: 1.5rem; height: 1.5rem"
                  :disabled="data.item.is_submit ? (data.item.is_approve && data.item.is_agree) : true"
                  :style="data.item.is_submit ? (data.item.is_approve ? 'color: grey;' : 'color: green;') : 'color: grey;'"
                  @click="performanceId = data.item.performance_id"
                />
                <!-- Submit {{data.item.is_submit}} -->
                <feather-icon
                  v-if="roles.selectedRole === 5"
                  v-b-modal.approve-employee
                  v-b-tooltip.hover.bottom="$t('Approve')"
                  :disabled="data.item.is_agree ? data.item.is_approve : true"
                  icon="CheckSquareIcon"
                  style=" width: 1.5rem; height: 1.5rem"
                  :style="data.item.is_agree ? (data.item.is_approve ? 'color: grey;' : 'color: green;') : 'color: grey;'"
                  @click="performanceId = data.item.performance_id"
                />
                <feather-icon
                  v-if="roles.selectedRole === 6 || roles.selectedRole === 1"
                  v-b-modal.approve-employee
                  v-b-tooltip.hover.bottom="$t('Approve')"
                  :disabled="data.item.is_submit ? data.item.is_approve : true"
                  icon="CheckSquareIcon"
                  style=" width: 1.5rem; height: 1.5rem"
                  :style="data.item.is_submit ? (data.item.is_approve ? 'color: grey;' : 'color: green;') : 'color: grey;'"
                  @click="performanceId = data.item.performance_id"
                />
                <feather-icon
                  v-if="roles.selectedRole === 1"
                  v-b-tooltip.hover.bottom="$t('Download')"
                  icon="DownloadIcon"
                  size="25"
                  class="hoverIcon ml-1"
                  @click="$bvModal.show('download-pdf'), employeeData = data.item"
                />
              </div>
            </template>
            <template #cell(hod_evaluation)="data">
              <div
                class=""
                style="color: #464e5f; text-align: center"
              >
                <p class="mt-1">
                  {{ data.item.hod_evaluation }}
                </p>
                <feather-icon
                  v-if="roles.selectedRole === 5"
                  v-b-modal.hod-evaluation
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style=" width: 1.5rem; height: 1.5rem; color: orange"
                  class=""
                  @click="tempSubs = data.item.subsidiary_id, tempPerf = data.item.performance_id, tempEval = data.item.hod_evaluation"
                />
              </div>
            </template>
            <template #cell(committee_evaluation)="data">
              <div
                class=""
                style="color: #464e5f; text-align: center"
              >
                <p class="mt-1">
                  {{ data.item.final_evaluation }}
                </p>
                <feather-icon
                  v-if="roles.selectedRole === 1"
                  v-b-modal.performance-grade
                  v-b-tooltip.hover.bottom="$t('Edit')"
                  icon="EditIcon"
                  style=" width: 1.5rem; height: 1.5rem; color: orange"
                  class=""
                  @click="tempSubs = data.item.subsidiary_id, tempPerf = data.item.performance_id, tempEval = data.item.final_evaluation"
                />
              </div>
            </template>
            <template #cell(revert)="data">
              <div
                v-if="roles.selectedRole === 1"
                class="d-flex align-items-center justify-content-center"
                style="cursor:pointer;"
              >
                <feather-icon
                  v-if="data.item.category !== 'Non Executive'"
                  v-b-modal.revert-performance-kra
                  v-b-tooltip.hover.bottom="$t('See Details')"
                  icon="EyeIcon"
                  color="purple"
                  size="25"
                  @click="tempUser = data.item.user_id, tempPerf = data.item.performance_id, approvalStatus = data.item.approval_status"
                />
                <feather-icon
                  v-else
                  v-b-modal.revert-performance
                  v-b-tooltip.hover.bottom="$t('See Details')"
                  icon="EyeIcon"
                  color="purple"
                  size="25"
                  @click="tempUser = data.item.user_id, tempPerf = data.item.performance_id, approvalStatus = data.item.approval_status"
                />
              </div>
              <div v-else>
                {{ $t("Not available") }}
              </div>
            </template>
            <template #cell(project_no)="data">
              {{ data.item.project_no }}
              <p
                v-b-modal.view-project
                class="mt-1"
                style="text-decoration: underline; color: #5555ff;"
                @click="selectedUser = data.item.user_id"
              >
                {{ $t("View Project") }}
              </p>
            </template>
            <template #cell(total_score)="data">
              {{ data.item.total_score }}
              <p
                v-b-modal.view-score
                class="mt-1"
                style="text-decoration: underline; color: #5555ff;"
                @click="tempPerf = data.item.performance_id"
              >
                {{ $t("Score Logs") }}
              </p>
            </template>
          </b-table>

          <b-modal
            id="revert-performance"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
            size="lg"
          >
            <RevertForm
              :performance="tempPerf"
              :user="tempUser"
              :approval="approvalStatus"
              @close="$bvModal.hide('revert-performance')"
              @revert-performance="getSummaryReport('search')"
            />
          </b-modal>
          <b-modal
            id="revert-performance-kra"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
            size="lg"
          >
            <RevertKra
              :performance="tempPerf"
              :user="tempUser"
              :approval="approvalStatus"
              @close="$bvModal.hide('revert-performance-kra')"
              @revert-performance="getSummaryReport('search')"
            />
          </b-modal>
          <b-modal
            id="edit-employee"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
            size="lg"
          >
            <EditEmployee
              :performance="tempPerf"
              :user="tempUser"
              :subsidiary-i-d="tempSubs"
              @close="$bvModal.hide('edit-employee')"
              @update-performance="getSummaryReport('search')"
            />
          </b-modal>
          <b-modal
            id="approve-employee"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <approve-employee
              :performance="performanceId"
              @close="$bvModal.hide('approve-employee')"
              @approve-performance="getSummaryReport('search')"
            />
          </b-modal>
          <b-modal
            id="approve-all-employee"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <approve-employee
              :all-approve="true"
              @close="$bvModal.hide('approve-all-employee')"
              @approve-all="approveAll()"
            />
          </b-modal>

          <b-modal
            id="carry-mark"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <alert-modal
              title="Are you sure to carry mark?"
              confirm-btn="Yes"
              @confirm="carryMark(performanceId)"
              @cancel="$bvModal.hide('carry-mark')"
            />
          </b-modal>

          <b-modal
            id="hod-evaluation"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <hodEvaluation
              :employee="tempSubs"
              :performance="tempPerf"
              :evaluation="tempEval"
              @close="$bvModal.hide('hod-evaluation')"
              @update-evaluation="getSummaryReport('search')"
            />
          </b-modal>

          <b-modal
            id="performance-grade"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
          >
            <perf-grade
              :employee="tempSubs"
              :performance="tempPerf"
              :evaluation="tempEval"
              @close="$bvModal.hide('performance-grade')"
              @update-evaluation="getSummaryReport('search')"
            />
          </b-modal>

          <b-modal
            id="view-project"
            :hide-footer="true"
            :hide-header="true"
            :centered="true"
            size="xl"
          >
            <viewProject :user-id="selectedUser" />
          </b-modal>

          <b-row
            v-if="finalData.length > 0"
            class="ml-1 mr-1"
          >
            <b-col>
              <span
                class="saj-text"
                align="start"
              >
                {{ $t("Showing") }} {{ from }} {{ $t("to") }} {{ to }} {{ $t("of") }} {{ rows }} {{ $t("entries") }}
              </span>
            </b-col>
            <b-col>
              <div class="d-flex align-items-center justify-content-end">
                <i
                  style="margin-right: 5px"
                  class="fa-solid fa-angles-left saj-page"
                  @click="currentPage = 1, getSummaryReport('search')"
                />
                <i
                  class="fa-solid fa-angle-left saj-page"
                  style=""
                  @click="currentPage !== 1 ? currentPage-- : currentPage === 1, getSummaryReport('search')"
                />
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Page')"
                  :rules="{
                    required,
                    max_value:lastPage,
                    min_value:1,
                  }"
                >
                  <b-form-input
                    v-model="currentPage"
                    class="p-0 text-center"
                    type="number"
                    style="width: 35px; height: 35px; background-color: red; color: white; border-radius: 20px; margin-right: 5px; margin-left: 5px;"
                    @change="currentPage > 0 && currentPage < lastPage + 1 ? getSummaryReport('search'): ''"
                  />
                  <small
                    class="text-danger"
                    style="color: #EA5455 !important;
                            position: absolute;
                            white-space: nowrap;
                            right: 25px;
                          "
                  >{{ errors[0] }}</small>
                </validation-provider>
                <i
                  class="fa-solid fa-angle-right saj-page"
                  style=""
                  @click="currentPage < lastPage ? currentPage++ : currentPage === rows, getSummaryReport('search')"
                />
                <i
                  class="fa-solid fa-angles-right saj-page"
                  style="margin-left: 5px"
                  @click="currentPage = lastPage, getSummaryReport('search')"
                />
              </div>
            </b-col>
          </b-row>

          <!-- <div
              v-if="isNextData"
              style="height: 30px; color: white; font-weight: 800;"
              class="btn btn-primary d-flex justify-content-center align-items-center mb-1"
              @click="getSummaryReport()"
            >
              {{ $t('See More') }}
            </div> -->
        </div>

        <div v-if="finalData.length > 0">
          <div
            v-if="showReport"
            class="d-flex justify-content-center mb-2"
          >

            <b-button

              v-b-modal.approve-all-employee
              md="4"
              class="p-1 saj-button btn-info mb-1"
              style=""
            >

              {{ $t('Approve All') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1 saj-button mb-1"
              :style="roles.isTopManagement || roles.isHOD || roles.isHR || roles.isAdmin ? `` : `display: none;`"
              :disabled="exporting"
              @click="exportReport()"
            >
              <div
                v-if="exporting"
                class="d-flex"
              >
                <b-spinner
                  small
                  type="grow"
                  class="mr-1"
                />
                <span
                  class="saj-button d-md-none d-lg-block"
                > {{ $t("Exporting") }} </span>
              </div>
              <div v-else>
                <span
                  class="saj-button d-md-none d-lg-block"
                > {{ $t("Download All") }} </span>
              </div>

            </b-button>

            <b-button
              md="4"
              class="p-1 saj-button btn-success ml-1 mb-1"
              :style="roles.isHOD || roles.isHR || roles.isAdmin ? `` : `display: none;`"
              style=""
              @click="triggerImportGrade()"
            >

              {{ $t('Upload Evaluation') }}
            </b-button>
            <input
              id="csvImport"
              type="file"
              style="visibility:hidden"
              @input="uploadCSV()"
            >

          </div>
        </div>

        <b-modal
          id="errMsg"
          :ok-only="true"
          :hide-header="true"
          :centered="true"
          :no-close-on-backdrop="true"
          :no-close-on-esc="true"
          :footer-class="`d-flex justify-content-center`"
          size="lg"

          @ok="errorMsg = []"
        >
          <div class="p-1">
            <h4 class="mb-1">
              {{ $t('Your Excel file failed to upload. Please fix according to the error(s) listed in the table below') }}.
            </h4>
            <b-table
              show-empty
              :items="errorMsg"
              :fields="errFields"
              responsive=""
              bordered
              class="mt-1 mb-1 saj-subtitle"
            >
              <template #head()="data">
                <span
                  class="saj-title"
                  style=""
                >{{ $t(data.label) }}</span>
              </template>

              <template #empty="">
                <h4 style="text-align: center; font-style: italic">
                  {{ $t('There are no records to show') }}
                </h4>
              </template>

              <template #cell(line)="data">
                <div
                  class="saj-text d-flex justify-content-center"
                >
                  {{ data.item.line }}
                </div>
                <!-- <div class="saj-text d-flex justify-content-center">
                {{ data.item.errors }}
              </div> -->
              </template>

              <template #cell(errors)="data">
                <div v-if="Array.isArray(lang === 'en' ? data.item.errors_bi : data.item.errors_bm)">
                  <div
                    v-for="(a, index) in (lang === 'en' ? data.item.errors_bi : data.item.errors_bm)"
                    :key="index"
                    class="saj-text d-flex justify-content-start"
                  >
                    {{ index +1 }}. {{ a }}
                  </div>
                </div>

                <div
                  v-else
                  class="saj-text"
                >

                  {{ lang === 'en' ? data.item.errors_bi : data.item.errors_bm }}
                  <div
                    v-for="(a, idx) in data.item.data"
                    :key="idx"
                    class="saj-text d-flex justify-content-start"
                  >
                    {{ idx +1 }}. {{ a }}
                  </div>
                </div>
              </template>
            </b-table>
          </div>

        </b-modal>

        <b-modal
          id="download-pdf"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
          size="lg"
        >
          <downloadPDF
            :employee-data="employeeData"
            @close="$bvModal.hide('download-pdf')"
          />
        </b-modal>
        <b-modal
          id="view-score"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
          size="l"
        >
          <viewScore :perf-id="tempPerf" />
        </b-modal>

        <!-- <div
            v-if="sReport"
            class="d-flex justify-content-center mb-2"
          >
            <vue-excel-xlsx
              :data="finalData"
              :columns="columns"
              :file-name="$t('Summary Report')"
              :file-type="'xlsx'"
              :sheet-name="'report'"
              style="border-radius: 5px;
                   background-color: red;
                   border: none;
                   padding-top: 5px;
                   padding-bottom: 5px;"
              class="saj-button"
            >
              {{ $t("EXPORT") }}
            </vue-excel-xlsx>
          </div> -->
      </b-overlay>

    </b-card>
  </div>
</template>
<script>
import FileSaver from 'file-saver'
import vSelect from 'vue-select'
import viewScore from "@/views/pages/summary/view-score.vue"
import {
  BTable, BTr, BTh, BCard,
  BCardHeader,
  // BCardBody,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  VBPopover,
  BPopover,
  BFormInput,
  BOverlay,
  BSpinner,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'
import VueExcelXlsx from "vue-excel-xlsx"
import Vue from "vue"
import Ripple from "vue-ripple-directive"
import { mapGetters } from 'vuex'
// import _ from 'lodash'
import SAJToast from "@/component/saj-toastification.vue"
import { ValidationProvider, extend } from 'vee-validate'
import {
  max_value,
  min_value,
} from 'vee-validate/dist/rules'
import { required } from '@validations'
import EditEmployee from "@/component/hod/EditEmployee.vue"
import ApproveEmployee from "@/component/hod/ApproveEmployee.vue"
import RevertForm from "@/component/hod/RevertForm.vue"
import RevertKra from "@/component/hod/RevertKra.vue"
import AlertModal from '@/pages/alert-modal.vue'
import perfGrade from '@/views/pages/summary/PerformanceGrade.vue'
import hodEvaluation from '@/views/pages/summary/hod-evaluation-update.vue'
import downloadPDF from "@/views/pages/summary/summary-report-pdf-modal.vue"
import viewProject from "@/views/pages/summary/view-project.vue"

extend('max_value', max_value)
extend('min_value', min_value)

Vue.use(VueExcelXlsx)

export default {
  components: {
    viewScore,
    AlertModal,
    BTable,
    BTh,
    BTr,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCard,
    // eslint-disable-next-line vue/no-unused-components
    BCardHeader,
    // BCardBody,
    BButton,
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    BPopover,
    BFormInput,
    BOverlay,
    BSpinner,
    ValidationProvider,
    BModal,
    EditEmployee,
    ApproveEmployee,
    RevertForm,
    RevertKra,
    // Template,
    perfGrade,
    vSelect,
    downloadPDF,
    hodEvaluation,
    viewProject,
  },
  directives: {
    "b-popover": VBPopover,
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {

  },

  data(){
    return {

      isInternal: false,
      tempEval: null,
      exporting: false,
      approvalStatus: [],
      jdkraSetting: null,
      execKRA: null,
      nonExecKRAMain: null,
      nonExecKRASide: null,
      approveList: [],
      tempUser: null,
      performanceId: null,
      tempPerf: null,
      tempSubs: null,
      currIdx: null,
      showReport: false,
      allWorkLocation: [],
      isNextData: true,
      show: false,
      additionalCol: 10,
      // maxDariEzra: 6,
      subsidiary_id: null,
      employeeData: null,
      jd_kra_status: null,
      core: 6,
      behaviour: 5,
      maxSub: null,
      apiData: null,
      searchname: null,
      searchEmpNo: null,
      companyID: '',
      selectedposition: '',
      selectedDistrict: '',
      selectedCategory: null,
      selectedFilter: "",
      selectedWork: null,
      rolesList: [],
      filterList: [],
      allFilter: [],
      allRoles: [],
      allDistrict: [],
      allEmpID: [],
      districtList: [],
      categoryList: [
        { text: `${this.$i18n.t('Choose Category')}`, value: null, disabled: true },
        { text: `${this.$i18n.t('Executive')}`, value: 'Executive' },
        { text: `${this.$i18n.t('Non Executive')}`, value: 'Non Executive' },
      ],
      statusList: [
        { text: `${this.$i18n.t('Choose Status')}`, value: null, disabled: true },
        { text: `${this.$i18n.t('Unsubmitted')}`, value: 'unsubmitted' },
        { text: `${this.$i18n.t('Submitted')}`, value: 'submit' },
        { text: `${this.$i18n.t('Agreed')}`, value: 'agree' },
        { text: `${this.$i18n.t('Supervisor Approved')}`, value: 'approved_sv' },
        { text: `${this.$i18n.t('Head of Department Approved')}`, value: 'approved_hod' },
        { text: `${this.$i18n.t('Human Resource Approved')}`, value: 'approved_hr' },
        { text: `${this.$i18n.t('Super Human Resource Approved')}`, value: 'approved_shr' },
      ],
      // empIDList: [],
      allWork: [],
      WorkList: [],
      subList: [],
      selectedSub: null,
      departmentList: [],
      selectedDepartment: null,
      selectedStatus: null,
      lang: null,
      errorMsg: [],
      errFields: [
        {
          key: 'line', label: 'Excel Row No', tdClass: 'text-center',
        },
        {
          key: 'errors', label: 'Error List', tdClass: 'text-left',
        },
      ],

      columns: [
        // { label: "Id", field: "employee_number" },
        // { label: "Name", field: "employee_name" },
        // { label: "Category", field: "category" },
        // { label: "District", field: "district" },
        // { label: "Kra/Jd", field: "jd_kra" },
        // { label: "Core", field: "competency_core" },
        // { label: "Behavioural", field: "competency_behaviour" },
        // { label: "Training & Development", field: "training" },
        // { label: "Contribution", field: "contribution" },
        // { label: "Total Score", field: "total_score" },
      ],

      allColumnField: [
        {
          key: 'index', label: 'No',
        },
        {
          key: 'employee_number', label: 'ID', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'employee_name', stickyColumn: true, label: 'Name', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'category', label: 'Category', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'grade', label: 'Grade', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },
        {
          key: 'work', label: 'Work Location', sortable: true, class: 'text-center', sortDirection: 'desc', thClass: 'custom-header-color', tdClass: "text-capitalize",
        },

      ],
      finalData: [],
      currUserId: null,
      required,
      currentPage: 1,
      perPage: null,
      to: null,
      from: null,
      rows: null,
      lastPage: null,
      kraCol: null,
      krajdCol: 1,
      hod_evaluation: null,
      selectedUser: null,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.lang = this.$i18n.locale
      this.currentPage = this.currIdx
      // this.getSummaryReport()
      this.categoryList = this.categoryList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Category'),
          }
        }
        return x
      })
      this.statusList = this.statusList.map(x => {
        if (x.value === null){
          return {
            ...x,
            text: this.$t('Choose Status'),
          }
        }
        return x
      })
      this.getAllDepartment()
      this.getAllSubsidiary()
    },
  },
  beforeMount() {

  },
  mounted() {
    this.lang = this.$i18n.locale
    this.getAllRoles()
    this.getMax()

    this.getAllSubsidiary()

    if (this.roles.selectedRole !== 4){
      this.getWorkLocation()
      this.getAllDepartment()
    }

    // this.getSummaryReport()
  },
  methods: {
    clearButton() {
      this.selectedDistrict = ""
      this.selectedposition = ""
      this.selectedCategory = null
      this.searchname = null
      this.searchEmpNo = null
      this.selectedWork = null
      this.currentPage = 1
      this.finalData = []
      this.selectedDepartment = null
      this.selectedSub = null
      // this.getSummaryReport()
      this.showReport = false
      this.selectedStatus = null
    },

    getAllRoles() {
      this.$axios.get(`${this.$baseUrl}/roles/getAllRoles`).then(response => {
        this.allRoles = response.data.data

        this.allRoles.forEach(role => {
          this.rolesList.push(role.name)
        })
      })
    },

    getMax() {
      const data = new FormData()
      data.append("subsidiary_id", this.subsidiary_id)
      // console.log("hereeeee", this.userDetails.business_unit)
      this.$axios.get(`${this.$baseUrl}/top_n/get_subsidiary_top_n?subsidiary_id=${this.userDetails.business_unit}`).then(response => {
        this.maxSub = response.data.data[0].top_n
        // console.log('max sub', this.maxSub)
      })
    },

    getKRAMax(search){
      let subs = null

      const params = new URLSearchParams()

      if (this.selectedSub !== null){
        subs = this.selectedSub
      } else {
        subs = this.userDetails.business_unit
      }

      params.append('subsidiary_id', subs)

      this.$axios.get(`${this.$baseUrl}/performance/kra_max`, { params }).then(res => {
        const kraMax = res.data.data[0]

        this.execKRA = kraMax.max_kra_executive
        this.nonExecKRAMain = kraMax.max_main_task
        this.nonExecKRASide = kraMax.max_side_task
        // console.log('get kra max', kraMax.max_main_task)
        this.alterData(search)
      })
    },

    getSetting(){
      let subs = null

      const params = new URLSearchParams()

      if (this.selectedSub !== null){
        subs = this.selectedSub
      } else {
        subs = this.userDetails.business_unit
      }

      params.append('subsidiary_id', subs)

      this.$axios.get(`${this.$baseUrl}/performancesetting/get_multiple_setting_form_jdkra_by_subsidiaries`, { params }).then(res => {
        // const msg = res.data

        this.jdkraSetting = res.data.data[0].jdkra_id

        // console.log('setting', res)
      })
    },

    carryMark(a){
      const params = new URLSearchParams()

      params.append('performance_id', a)

      this.$axios.post(`${this.$baseUrl}/performance/carry_mark`, params).then(res => {
        const msg = res.data
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: msg.message,
              icon: "CheckCircleIcon",
              variant: "info",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
        this.$bvModal.hide("carry-mark")
      })
    },

    getAllApproveList(){
      const currRole = this.roles.selectedRole
      this.approveList = []
      const params = new URLSearchParams()

      if (this.roles.selectedRole === 3 || this.roles.selectedRole === 4){
        params.append('sv_id', this.userDetails.user_id)
      }
      if (this.roles.selectedRole === 6) { // if HR
        params.append('subsidiary_id', this.userDetails.business_unit)
      }
      if (this.roles.selectedRole === 5) { // if HOD
        params.append('department_id', this.userDetails.department_id)
      }
      if (this.selectedCategory !== null && this.roles.selectedRole !== 1) {
        params.append('category', this.selectedCategory)
      }

      if (this.roles.selectedRole === 1) { // if SHR
        if (this.selectedCategory !== null) {
          params.append('category', this.selectedCategory)
        }
        if (this.selectedSub !== null) {
          params.append('subsidiary_id', this.selectedSub)
        }
        if (this.selectedWork !== null) {
          params.append('branch_id', this.selectedWork)
        }
        if (this.selectedDepartment !== null) {
          params.append('department_id', this.selectedDepartment)
        }
        if (this.searchname !== null){
          params.append('employee_name', this.searchname)
        }
        if (this.searchEmpNo !== null){
          params.append('employee_number', this.searchEmpNo)
        }
      }

      this.$axios.get(`${this.$baseUrl}/employees/getAllEmployeesName`, { params }).then(res => {
        const item = res.data.data
        // console.log('a', item)
        // if (this.approveList !== null){
        //   this.approveList = null
        // }
        item.forEach(a => {
          switch (currRole){
            case 1:
              // Admin
              if (a.performance_status.approved_shr === 0){
                this.approveList.push({
                  perfId: a.performance_id,
                })
              }
              break
            case 3:
              // Exec

              if (a.performance_status.submit === 1){
                if (a.performance_status.agree === 0) {
                  this.approveList.push({
                    perfId: a.performance_id,
                  })
                }
              }
              break
            case 4:
              // TM
              if (a.performance_status.submit === 1){
                if (a.performance_status.agree === 0) {
                  this.approveList.push({
                    perfId: a.performance_id,
                  })
                }
              }
              break
            case 5:
              // HOD
              if (a.performance_status.agree === 1){
                if (a.performance_status.approved_hod === 0){
                  this.approveList.push({
                    perfId: a.performance_id,
                  })
                }
              }
              break
            case 6:
              // HR
              if (a.performance_status.approved_hod === 1){
                if (a.performance_status.approved_hr === 0){
                  this.approveList.push({
                    perfId: a.performance_id,
                  })
                }
              }
              break
            default:
              break
          }
        })
      })
    },

    approveAll(){
      const currRole = this.roles.selectedRole
      // console.log('list', this.approveList)

      const data = new FormData()

      this.approveList.forEach(id => {
        data.append(`performance_id[]`, id.perfId)
      })

      if (currRole === 3){
        data.append(`sv_approve`, 1)
      }

      if (currRole === 5){
        data.append(`hod_approve`, 1)
      }

      if (currRole === 4){
        data.append(`sv_approve`, 1)
      }

      if (currRole === 6){
        data.append(`hr_approve`, 1)
      }

      if (currRole === 1){
        data.append(`shr_approve`, 1)
      }

      // if (currRole === 3){
      //   data.forEach(a => {
      //     console.log(a)
      //   })
      // }

      // if (this.approveList.length !== 0){

      // eslint-disable-next-line no-restricted-syntax
      // for (const pair of data.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      this.$axios.post(`${this.$baseUrl}/performance/approve`, data).then(() => {
        setTimeout(() => {
          this.$bvModal.hide("approve-all-employee")
          this.getSummaryReport('search')
          this.getAllApproveList()
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully approved')}!`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }, 1000)
      }).catch(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('No performances to approve')}!`,
              icon: "XIcon",
              variant: "danger",
              iconBg: '#e80202',
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'error',
          },
        )
        // console.log('message:', error.response.data.message)
      })

      this.$bvModal.hide('approve-all-employee')
    },

    getSummaryReport(search = 'no') { // filter and get all data
      // console.log('details user: ', this.userDetails)
      this.show = true
      // required parameters : department_id for hod
      // eslint-disable-next-line no-plusplus
      // console.log('user detail', this.userDetails)

      this.getSetting() // determine jd kra

      // this.getKRAMax()

      const params = new URLSearchParams()
      if (this.selectedDepartment !== null) {
        params.append('department_id', this.selectedDepartment)
      } else if (this.roles.selectedRole === 5) { // if HOD
        params.append('department_id', this.userDetails.department_id)
      }

      if (this.selectedWork !== null) {
        params.append('branch_id', this.selectedWork)
      }
      if (this.selectedCategory !== null) {
        params.append('category', this.selectedCategory)
      }
      if (this.searchname !== null) {
        params.append('employee_name', this.searchname)
      }
      if (this.searchEmpNo !== null){
        params.append('employee_number', this.searchEmpNo)
      }
      if (this.selectedSub !== null) {
        params.append('subsidiary_id', this.selectedSub)
      }
      if (this.roles.selectedRole === 5) {
        params.append('subsidiary_id', this.userDetails.business_unit)
      } else if (this.roles.selectedRole === 6) { // if HR
        params.append('subsidiary_id', this.userDetails.business_unit)
      } else if (this.roles.selectedRole === 3 || this.roles.selectedRole === 4) {
        if (this.selectedSub === null) {
          params.append('subsidiary_id', this.userDetails.business_unit)
        }
      }

      // if (this.roles.selectedRole === 4){
      //   params.append('tm_id', this.userDetails.user_id)
      // }

      if (this.roles.selectedRole === 3 || this.roles.selectedRole === 4){
        params.append('sv_id', this.userDetails.user_id)
      }

      if (this.selectedStatus !== null){
        params.append('status', this.selectedStatus)
      }

      // console.log('sini', this.selectedWork, this.selectedCategory, this.selectedSub, this.selectedDepartment)

      params.append('page', this.currentPage)

      params.append('user_status', 'active')

      //   params.forEach(x => {
      //     console.log(x)
      //   })

      // eslint-disable-next-line no-restricted-syntax
      // for (const pair of params.entries()) {
      //   console.log(`${pair[0]}, ${pair[1]}`)
      // }

      this.$axios.post(`${this.$baseUrl}/summary/summary_report`, params).then(response => {
        // console.log('senarai pekerja', response)
        this.showReport = true
        // this.getAllApproveList()
        const summaryReport = response.data.data
        const paging = response.data

        this.from = paging.from
        this.to = paging.to
        this.rows = paging.total
        this.perPage = paging.per_page
        this.lastPage = paging.last_page
        this.currIdx = this.currentPage
        this.isInternal = response.data.internal_industry_status === 'enable'

        // console.log('summ report', paging)
        if (summaryReport.length === 0) {
          this.isNextData = false
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$i18n.t(`No more data`)}.`,
                icon: "CheckCircleIcon",
                variant: "info",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
        this.apiData = summaryReport
        // console.log("hereee", this.apiData)

        this.getKRAMax(search)

        // setTimeout(() => {
        //   // this.show = false
        //   this.alterData(search)
        //   console.log("timeee")
        // }, 2000)

        // this.alterData(search)
        // this.show = false
      }).catch(() => {
        // console.log(error.message)
        this.show = false
      })
    },
    getWorkLocation(){
      const a = this.selectedSub

      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${a}`).then(res => {
        const work = res.data.data
        this.WorkList = work.map(x => ({
          text: x.name,
          value: x.id,
        }))
      })
    },
    getData(){
      this.getAllDepartment()
      this.getWorkLocation()
      if (this.selectedWork !== null){
        this.selectedWork = null
      }
      if (this.selectedDepartment !== null){
        this.selectedDepartment = null
      }
    },
    getAllSubsidiary() {
      const config = {
        method: 'get',
        url: `${this.$baseUrl}/subsidiary/getAll`,
      }

      this.$axios(config)
        .then(response => {
          const result = response.data
          if (result.success) {
            const subs = result.data.subsidiaries

            this.subList = subs.map(sub => ({
              text: sub.subsidiary_name,
              value: sub.id,
            }))
          }
        })
        .catch(() => {
          // console.log(error)
        })
    },
    getAllDepartment() {
      const a = this.selectedSub

      const config = {
        method: 'get',
        url: `${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${a}`,
      }

      this.$axios(config)
        .then(response => {
          this.departmentList = []
          // this.departmentList.push({
          //   text: 'Choose Department',
          //   value: null,
          //   disabled: true,
          // })
          const result = response.data
          if (result.success) {
            const depts = result.data

            this.departmentList = depts.map(dep => ({
              text: dep.name,
              value: dep.id,
            }))
          }
        })
        .catch(() => {
          // console.log(error)
        })
    },

    alterData(search) {
      const allTrainingScore = []
      if (this.allColumnField.length > 7) {
        this.allColumnField.splice(6, (this.allColumnField.length - 5))
      }
      // console.log('kra col', this.apiData.)
      // console.log(allTrainingScore)
      // eslint-disable-next-line no-plusplus

      if (this.selectedCategory === 'Non Executive'){
        if (this.jdkraSetting === 0){
          allTrainingScore.push({
            key: `jd`,
            label: `${this.$i18n.t(`JD`)}`,
            class: 'text-center',
          })

          this.krajdCol = 1
          // console.log('jd non exec', this.jdkraSetting)
        } else {
          for (let j = 0; j < this.nonExecKRAMain; j += 1) {
            allTrainingScore.push({
              key: `main${j}`,
              label: `${this.$i18n.t(`MAIN`)} ${j + 1}`,
              class: 'text-center',
            })
          }

          for (let j = 0; j < this.nonExecKRASide; j += 1) {
            allTrainingScore.push({
              key: `side${j}`,
              label: `${this.$i18n.t(`SIDE`)} ${j + 1}`,
              class: 'text-center',
            })
          }
          this.krajdCol = this.nonExecKRAMain + this.nonExecKRASide
          // console.log('kra non exec')
        }
      }

      if (this.selectedCategory === 'Executive'){
        for (let j = 0; j < this.execKRA; j += 1) {
          allTrainingScore.push({
            key: `kra${j}`,
            label: `${this.$i18n.t(`KRA`)} ${j + 1}`,
            class: 'text-center',
          })
        }
        this.krajdCol = this.execKRA
        // console.log('kra exec', this.execKRA)
        // console.log('kra exec', allTrainingScore)
      }

      for (let j = 0; j < this.core; j += 1) {
        allTrainingScore.push({
          key: `compentency_core${j}`,
          label: `${this.$i18n.t(`core`)} ${j + 1}`,
          class: 'text-center',
        })
      }
      for (let k = 0; k < this.behaviour; k += 1) {
        allTrainingScore.push({
          key: `competency_behaviour${k}`,
          label: `${this.$i18n.t(`behaviour`)} ${k + 1}`,
          class: 'text-center',
        })
      }
      for (let i = 0; i < this.maxSub; i += 1) {
        allTrainingScore.push({
          key: `training${i}`,
          label: `${this.$i18n.t(`training`)} ${i + 1}`,
          class: 'text-center',
        })
      }

      if (this.isInternal){
        allTrainingScore.push({
          key: `contribution`,
          label: `Involvement`,
          class: 'text-center',
        })
      }

      allTrainingScore.push({
        key: `project_no`,
        label: `Project Management`,
        sortable: true,
        class: 'text-center',
      })

      allTrainingScore.push({
        key: `total_score`,
        label: `Total Score`,
        sortable: true,
        class: 'text-center',
      })
      allTrainingScore.push({
        key: `performance_grade`,
        label: `Performance Grade`,
        sortable: true,
        class: 'text-center',
      })
      allTrainingScore.push({
        key: `hod_evaluation`,
        label: `HOD Evaluation`,
        sortable: true,
        class: 'text-center',
      })
      if (this.roles.selectedRole === 1 || this.roles.selectedRole === 6){
        allTrainingScore.push({
          key: `committee_evaluation`,
          label: `Committee Evaluation`,
          class: 'text-center',
        })
      }

      allTrainingScore.push({
        key: `comment`,
        label: `Comment`,
        class: 'text-center',
      })
      allTrainingScore.push({
        key: 'appraiser_comment',
        label: 'Supervisor Comment',
        class: 'text-center',
      })
      allTrainingScore.push({
        key: `action`,
        label: `Action`,
        class: 'text-center',
      })
      allTrainingScore.push({
        key: `status`,
        label: `Status`,
        class: 'text-center',
      })
      // allTrainingScore.push({
      //   key: `revert`,
      //   label: `Revert Form`,
      //   class: 'text-center',
      // })
      // console.log('column', this.allColumnField)
      // console.log('training score before splice', allTrainingScore)
      if (this.allColumnField.length < 7) {
        // console.log('col', this.allColumnField)
        this.allColumnField.splice(7, 0, ...allTrainingScore)
      }

      // console.log('column after splice', this.allColumnField)

      this.columns = []

      this.allColumnField.forEach(a => {
        this.columns.push({
          label: a.label,
          field: a.key,
        })
      })

      // eslint-disable-next-line no-unused-vars
      // this.finalData = []
      if (search === 'search') {
        this.finalData = []
      }

      // console.log('apidata', this.apiData)

      this.apiData.forEach(item => {
        const allTraining = {}

        for (let i = 0; i < this.maxSub; i += 1) {
          const key = `training${i}`
          if (item.training !== null) {
            const tempData = item.training[i]
            allTraining[key] = tempData !== undefined ? tempData.score : 0
          } else {
            allTraining[key] = 0
          }
        }

        if (this.selectedCategory === 'Executive'){
          for (let i = 0; i < this.execKRA; i += 1) {
            const key = `kra${i}`
            if (item.jd_kra_data !== null) {
              const tempData = item.jd_kra_data[i]
              // console.log('kra exec', tempData)
              allTraining[key] = tempData !== undefined ? tempData.score : 0
            } else {
              allTraining[key] = 0
            }
          }
          // console.log('data exec')
        } else if (this.selectedCategory === 'Non Executive'){
          if (this.jdkraSetting === 0){
            // eslint-disable-next-line dot-notation
            allTraining[`jd`] = item.jd_kra_total
          } else {
            for (let i = 0; i < this.nonExecKRAMain; i += 1) {
              const key = `main${i}`
              if (item.jd_kra_data !== null) {
                const tempData = item.jd_kra_data[0]
                // console.log('kenapaaaaa', tempData)
                if (tempData.main_task !== null && tempData.main_task !== undefined){
                  // console.log('main task ini', item.employee_name, tempData.main_task[i])
                  allTraining[key] = tempData.main_task[i] !== undefined ? tempData.main_task[i].score : 0
                } else {
                  // console.log('main task null')
                  allTraining[key] = 0
                }
              } else {
                // console.log('jd kra data tak ada')
                allTraining[key] = 0
              }
              // console.log(item.employee_name, key, 'is', allTraining[key])
            }

            for (let i = 0; i < this.nonExecKRASide; i += 1) {
              const key = `side${i}`
              if (item.jd_kra_data !== null) {
                const tempData = item.jd_kra_data[0]
                if (tempData.side_task !== null && tempData.side_task !== undefined){
                  // console.log('main task ini', item.employee_name, tempData.side_task[i])
                  allTraining[key] = tempData.side_task[i] !== undefined ? tempData.side_task[i].score : 0
                } else {
                  // console.log('main task null')
                  allTraining[key] = 0
                }
              } else {
                // console.log('jd kra data tak ada')
                allTraining[key] = 0
              }
              // console.log(item.employee_name, key, 'is', allTraining[key])
            }
          }
          // console.log('data non exec', item.jd_kra_total)
        }
        // if (item.compentency_core !== null) {
        //   item.compentency_core.forEach((data, index) => {
        //     const key = `compentency_core${index}`
        //     //   [key] = data.score
        //     allTraining[key] = data.score
        //   })
        // }

        for (let i = 0; i < this.core; i += 1) {
          const key = `compentency_core${i}`
          if (item.compentency_core !== null) {
            const tempData = item.compentency_core[i]
            allTraining[key] = tempData !== undefined ? tempData.score : 0
          } else {
            allTraining[key] = 0
          }
        }

        // if (item.competency_behaviour !== null) {
        //   item.competency_behaviour.forEach((data, index) => {
        //     const key = `competency_behaviour${index}`
        //     //   [key] = data.score
        //     allTraining[key] = data.score
        //   })
        // }

        for (let i = 0; i < this.behaviour; i += 1) {
          const key = `competency_behaviour${i}`
          if (item.competency_behaviour !== null) {
            const tempData = item.competency_behaviour[i]
            allTraining[key] = tempData !== undefined ? tempData.score : 0
          } else {
            allTraining[key] = 0
          }
        }

        // console.log('kra data', item.jd_kra_data)

        let isSubmit = false
        let isAgreed = false
        let isApprove = false
        // let isCarryMark = false

        switch (this.roles.selectedRole){
          case 1:
            // Admin
            // isApprove = item.approval_status.approved_hr === 1

            // if (item.approval_status.approved_hr === 1){
            if (item.approval_status.approved_shr === 1){
              isApprove = true
            } else {
              isApprove = false
            }
            isSubmit = true
            // } else {
            //   isApprove = false
            // }
            // console.log('1')
            break
          case 3:
            // Exec
            // isSubmit = item.approval_status.submit
            // isAgreed = item.approval_status.agree
            if (item.approval_status.approved_shr === 0){
              if (item.approval_status.submit === 1){
                if (item.approval_status.agree === 1){
                  isAgreed = true
                  isApprove = true
                } else {
                  isApprove = false
                }
                isSubmit = true
              } else {
              // isSubmit = false
                isApprove = false
              }
            } else {
              isApprove = true
              isSubmit = true
              isAgreed = true
            }

            // console.log('3')
            break
          case 4:
            // TM
            if (item.approval_status.approved_shr === 0){
              if (item.approval_status.submit === 1){
                if (item.approval_status.agree === 1){
                // isAgreed = true
                  isApprove = true
                  isAgreed = true
                } else {
                  isApprove = false
                }
                isSubmit = true
              } else {
              // isSubmit = false
                isApprove = false
              }
            } else {
              isApprove = true
              isSubmit = true
              isAgreed = true
            }

            // console.log('4')
            break
          case 5:
            // HOD
            if (item.approval_status.agree === 1){
              if (item.approval_status.approved_hod === 1){
                isApprove = true
              } else {
                isApprove = false
              }
              isAgreed = true
            } else {
              isAgreed = false
              isApprove = false
            }
            // console.log('5')
            break
          case 6:
            // HR
            if (item.approval_status.approved_hod === 1){
              if (item.approval_status.approved_hr === 1){
                isApprove = true
              } else {
                isApprove = false
              }
              isSubmit = true
            } else {
              isApprove = false
            }

            // console.log('6')
            break
          default:
            break
        }

        // console.log('job task score', item.appraiser_comment)

        this.finalData.push(
          {
            performance_id: item.performance_id,
            employee_number: item.employee_number,
            employee_name: item.employee_name,
            user_id: item.user_id,
            category: item.category,
            grade: item.grade,
            work: item.work_location,
            jd_kra_total: item.jd_kra_total,
            contribution: item.contribution.score,
            total_score: item.total_score,
            performance_grade: item.performance_grade === null ? '-' : item.performance_grade,
            final_evaluation: item.final_evaluation === null ? '-' : item.final_evaluation,
            comment: item.comment === null || item.comment.comment === null ? '-' : item.comment.comment,
            appraiser_comment: item.appraiser_comment === null || item.appraiser_comment.comment === null ? '-' : item.appraiser_comment.comment,
            approved_at: item.performance_approved_at,
            action: null,
            jd_kra_status: item.jd_kra_status,
            // job_task_score: item.jd_kra_data ? item.jd_kra_data[0].job_task_score : "",
            // job_subtask_score: item.jd_kra_data ? item.jd_kra_data[0].job_subtask_score : "",
            jd_kra_data: item.jd_kra_data,
            status: item.approval_status.message,
            is_submit: isSubmit,
            is_agree: isAgreed,
            is_approve: isApprove,
            approval_status: item.approval_status,
            subsidiary_id: item.subsidiary_id,
            grade_set: item.grade_set,
            department_id: item.department_id,
            role: item.role,
            hod_evaluation: item.hod_evaluation,
            project_no: item.total_project_management,
            // subsidiary_id: item.business_unit,
            // jd: this.selectedCategory === 'Non Executive' && this.jdkraSetting === 0 ? item.jd_kra_total : null,
            // kra: kraData,

            ...allTraining,
          },
        )
        // console.log('all data', this.finalData)
      })
      this.show = false
    },
    exportReport(){
      this.exporting = true
      // const params = new URLSearchParams()
      // // params.append(`subsidiary_id`, this.userDetails.business_unit)
      // // params.append(`department_id`, this.userDetails.department_id)

      this.$toast(
        {
          component: SAJToast,
          props: {
            title: `${this.$t('Your Excel file is currently processing')}.`,
            text: `${this.$t('Please wait, and do not reload or leave this page')}!`,
            icon: "InfoIcon",
            iconBg: '#53b7db',
            variant: "info",
            titleColor: "#000",
          },
        },
        {
          position: "top-right",
          type: 'info',
        },
      )

      let param = null

      // console.log("sini role", this.roles.selectedRole)

      if (this.roles.selectedRole === 5){
        param = `subsidiary_id=${this.userDetails.business_unit}&department_id=${this.userDetails.department_id}`
      } else if (this.roles.selectedRole === 6){
        param = `subsidiary_id=${this.userDetails.business_unit}`
        // console.log("haloo", this.userDetails.business_unit)
        // param = `subsidiary_id=${this.userDetails.business_unit}`
      } else if (this.roles.selectedRole === 4) { // Top Management
        param = `supervisor_id=${this.userDetails.user_id}`
      } else if (this.roles.selectedRole === 1){
        param = `subsidiary_id=${this.selectedSub}`
        // console.log("haloo", this.selectedSub)
      }
      if (this.selectedDepartment !== null){
        param += `&department_id=${this.selectedDepartment}`
        // console.log("dept & subs id", this.userDetails.business_unit, this.selectedDepartment)
      }

      if (this.selectedWork !== null){
        param += `&branch_id=${this.selectedWork}`
      }
      if (this.searchname !== null){
        param += `&employee_name=${this.searchname}`
      }
      if (this.searchEmpNo !== null){
        param += `&employee_number=${this.searchEmpNo}`
      }

      param += `&status=active`
      // this.$axios.get(`${this.$baseUrl}/summary/export_summary_report`, { params }).then(res => {
      //   console.log('masukkkkk', JSON.stringify(res))
      // const FileSaver = require('file-saver')
      // const blob = new Blob(["Hello, world!"], { type: "text/plain;charset=utf-8" })
      // FileSaver.saveAs(response.data, "image.xlsx")
      // if (res.data.success === true){
      //   this.$toast(
      //     {
      //       component: SAJToast,
      //       props: {
      //         icon: "InfoIcon",
      //         iconColor: 'white',
      //         iconBg: '#53b7db',
      //         // iconBg: 'rgb(255,0,15, 1)',
      //         titleColor: '#000',
      //       },
      //     },
      //     {
      //       position: "top-right",
      //       type: 'info',
      //     },
      //   )
      // }
      // })
      // console.log('parameter', param)
      // const url = `http://52.74.19.0:8000/api/summary/export_summary_report?${param}`
      // const cutURL = url.split('.com:8080/')
      // console.log('url', cutURL[0].concat(`/${cutURL[1]}`))
      // FileSaver.saveAs(`${[url]}`, "Performance_Management_Summary.xlsx")

      let x

      if (param === null){
        x = `https://api.ranhill-pms.com/api/summary/export_summary_report`
      } else {
        x = `https://api.ranhill-pms.com/api/summary/export_summary_report?${param}`
      }

      this.$axios.get(`${x}`, {
        responseType: 'blob',
      }).then(response => {
        // console.log('file dw', response)
        if (response.status === 200){
          this.exporting = false
          FileSaver.saveAs(response.data, "Performance_Management_Summary.xlsx")
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Your Excel file will be downloaded soon')}!`,
                icon: "CheckCircleIcon",
                variant: "info",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }
      })
        .catch(() => {
          // console.log(error)
        })
    },
    triggerImportGrade() {
      document.getElementById('csvImport').value = null
      document.getElementById('csvImport').click()
    },
    uploadCSV() {
      const file = document.getElementById('csvImport').files[0]

      const data = new FormData()
      data.append('file', file)
      if (this.roles.selectedRole === 1) {
        data.append('subsidiary_id', this.selectedSub)
      } else if (this.roles.selectedRole === 5 || this.roles.selectedRole === 6){
        data.append('subsidiary_id', this.userDetails.business_unit)
        // console.log("hereee", this.userDetails.business_unit)
      }
      // data.forEach(x => {
      //   console.log(x)
      // })
      // console.log('file', file)
      // eslint-disable-next-line no-unused-vars
      const config = {
        method: 'post',
        url: `${this.$baseUrl}/summary/import_summary_report`,
        data,
      }

      this.$axios(config)
        .then(res => {
          // console.log('file res', res)
          if (res.data.success === true){
            this.getSummaryReport('search')
            this.$toast(
              {
                component: SAJToast,
                props: {
                  title: res.data.message,
                  icon: "InfoIcon",
                  iconColor: 'white',
                  iconBg: '#53b7db',
                  // iconBg: 'rgb(255,0,15, 1)',
                  titleColor: '#000',
                },
              },
              {
                position: "top-right",
                type: 'info',
              },
            )
          } else {
            const val = res.data.data

            // console.log('err', val)

            val.forEach(k => {
              this.errorMsg.push({
                line: k.line !== undefined ? k.line : '-',
                errors_bi: k.error_bi,
                errors_bm: k.error_bm,
                data: k.data !== undefined ? k.data : null,
              })
            })
            this.$bvModal.show('errMsg')
          }
        }).catch(() => {

        })
    },
    viewHistory() {
      this.$router.push({ name: 'history-performance-tm' })
    },

  },
}
</script>
  <style scoped>
  ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
  }

  :hover::-webkit-scrollbar {
      width: 10px;
      height: 10px;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
  }

  :hover::-webkit-scrollbar-thumb {

      /* background-color: #a0a0a5 !important; */
      background-color: rgb(255, 0, 0)
  }

  ::-webkit-scrollbar-thumb:hover {
      border: 2px solid #bbb9b9 !important;
  }
  </style>
  <style type="text/css">
      .popover{
          max-width:600px;
      }
  </style>
  <style>
  .table thead th {
      font-size: 1rem !important;
  }
  </style>
