<template>
  <div>

    <!-- ni sv -->
    <!-- <template v-if="filterProducts.length === 0">
      <span>No team members yet...</span>
    </template> -->
    <b-tabs
      v-model="tabNo"
      nav-class="saj-title m-0 mb-2"
      style="
                font-family: Poppins;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
                letter-spacing: 0.1em;
              "
    >
      <!-- Performance Management -->
      <b-tab
        :title="$t('Performance Management Summary')"
      >
        <projectManagementTopMan v-if="roles.selectedRole === 4" />
        <project-management v-else />
      </b-tab>
      <b-tab
        :title="$t('Performance Details')"
      >
        <template v-if="rolesList.length !== 0">
          <b-card
            no-body
            class="mr-1"
            style=""
          >
            <b-card-header class="p-1">
              <h5 class="saj-title">
                {{ $t("Search Filter") }}
              </h5>
            </b-card-header>
            <b-card-body>
              <b-row
                style="display: flex;
                   align-items: flex-end;
                  "
              >
                <b-col
                  cols="12"
                  md="4"
                  class="mb-md-0 "
                >
                  <label class="saj-text">{{ $t("Employee Number") }}:</label>
                  <!-- <b-form-select
                    v-model="selectedEmployeeID"
                    :options="empIDList"
                    :value="empIDList"
                    class="saj-text"
                  /> -->
                  <v-select
                    v-model="selectedEmployeeID"
                    class="saj-text select-size-md"
                    style="background: white"
                    :placeholder="$t('Choose Employee Number')"
                    :options="empIDList"
                    :value="empIDList"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mb-md-0 "
                >
                  <label class="saj-text">{{ $t("Department") }}:</label>
                  <b-form-select
                    v-model="selectedDept"
                    :options="DepartmentList"
                    :value="DepartmentList"
                    class="saj-text"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mb-md-0"
                >
                  <label class="saj-text">{{ $t("Work Location") }}:</label>
                  <b-form-select
                    v-model="selectedWork"
                    :options="WorkList"
                    :value="WorkList"
                    class="saj-text"
                  />
                  <!-- <b-form-select
              v-model="selectedDistrict"
              :options="districtList"
              :value="districtList"
              size="lg"
              class="saj-text"
            /> -->
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mb-md-0 mb-2"
                >
                  <span class="saj-text">{{ $t("Position") }}:</span>
                  <b-form-select
                    v-model="selectedposition"
                    :options="rolesList"
                    :value="rolesList"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="4"
                  class="mb-md-0 mb-2 mt-1"
                >
                  <span class="saj-text">{{ $t("Search Name") }}:</span>
                  <b-form-input
                    id="search"
                    v-model="searchname"
                    :placeholder="$t('Search Name') + '...'"
                  />
                </b-col>
                <b-col>
                  <b-button
                    md="2"
                    variant="primary"
                    class="d-flex justify-content-center saj-button"
                    block
                    @click="filterEmployee(), show = true"
                  >
                    <!-- <feather-icon icon="SearchIcon" /> -->
                    <span
                      class="d-md-none d-lg-block"
                    > {{ $t("Search") }} </span>
                  </b-button>
                </b-col>
                <b-col>
                  <b-button
                    md="2"
                    variant="primary"
                    class="d-flex justify-content-center saj-button"
                    block

                    @click="clearButton(), show = true"
                  >
                    <!-- <feather-icon icon="SearchIcon" /> -->
                    <span
                      class="d-md-none d-lg-block"
                    > {{ $t("Clear") }} </span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </template>
        <template v-else>
          <!-- <span>{{ $t("No roles") }}</span> -->
        </template>
        <b-overlay
          :show="show"
          rounded="sm"
        >

          <template #overlay>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <b-spinner
                variant="primary"
                label="Spinning"
              />
              <p class="mt-1">
                {{ $t('Fetching Data') }}...
              </p>
            </div>

          </template>

          <template
            v-if="items.length !== 0"
          >
            <transition-group
              name="
        list"
              mode="out-in"
              class="row"
            >

              <div

                v-for="(item) in items"
                :key="item.employee.id"
                class="mr-1"
              >
                <b-card
                  no-body
                  class="mb-1 ml-1"
                  style="
                          background: #ffffff;
                          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
                            0px 0px 41px 9px rgba(0, 0, 0, 0.11);
                          border-radius: 5%;
                          min-height: 25rem;
                          min-width: 25rem;
                          max-height: 25rem;
                          max-width: 25rem;
                          overflow-y: auto;
                          font-size: 1.35rem;
                        "
                >
                  <b-card-body
                    class="d-flex flex-column justify-content-between p-2"
                    style="height:100%;"
                  >
                    <div>
                      <b-row>
                        <b-col cols="4">
                          <b-img
                            :src="item.profile_picture.file === null ? require(`@/assets/images/user.png`) : item.profile_picture.file.url"
                            class=""
                            style="object-fit: cover;
                           border-radius: 50%;
                           height: 4em;
                           width: 4em;
                           "
                          />
                        </b-col>
                        <b-col cols="8">
                          <div class="saj-subtitle">
                            {{ item.user.name }}
                          </div>
                          <div class="saj-text">
                            ({{ item.employee.position }})
                          </div>
                        </b-col>
                      </b-row>
                      <b-row class="m-0 mt-2 d-flex">
                        <b-col
                          cols="2"
                          class="d-flex justify-content-top align-items-start"
                        >
                          <div>
                            <feather-icon
                              icon="MailIcon"
                              size="22"
                              stroke="black"
                              class=""
                            />
                          </div>
                        </b-col>
                        <b-col
                          cols="10"
                          class="saj-text"
                        >
                          <div>
                            {{ item.user.email }}
                          </div>
                          <div>
                            {{ item.employee.employee_number }}
                          </div>
                          <div>
                            {{ item.employee.department_name }}
                          </div>
                          <div>
                            {{ item.employee.branch_name }}
                          </div>
                          <div>
                            {{ $t("Self Score") }}:{{ item.user.performance.self_score }}
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                    <div>
                      <router-link
                        class="m-0"
                        :to="{
                          name: 'team-performance-individual',
                          params: {
                            id: item.user.id,
                            performance_id: item.user.performance !== null ? item.user.performance.id : '-',
                          },
                        }"
                      >
                        <b-button
                          class="saj-button m-0 mt-1"
                          variant="primary"
                          block
                        >
                          {{ $t("See Details") }}
                        </b-button>
                      </router-link>
                    </div>
                  </b-card-body>
                </b-card>
              </div>

            </transition-group>
          </template>
          <template v-else>
            <!-- cantikkan sikit -->
            <div
              class="saj-text d-flex align-items-center justify-content-center bg-white p-1 m-0 mb-3 mr-1"
              style="font-style: italic; background: rgb(247, 247, 252); text-align: center; height: 500px; border-radius:20px "
            >

              {{ $t('Team members not available') }}

            </div>
          </template>
        </b-overlay>
      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
import {
  BCard,
  // BCardHeader,
  BCardBody, BRow, BCol, BImg,
  BButton, BFormInput, BFormSelect,
  BOverlay, BSpinner, BCardHeader,
  BTabs, BTab,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
// import vSelect from 'vue-select'
// import axios from "axios"
import _ from 'lodash'
import projectManagement from "@/views/pages/summary/summary-report-global.vue"
import projectManagementTopMan from "@/views/pages/summary/summary-report-top-management.vue"
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    // BCardHeader,
    BCardBody,
    BButton,
    BImg,
    // vSelect,
    // eslint-disable-next-line vue/no-unused-components
    // Individual,
    BFormInput,
    BFormSelect,
    BOverlay,
    BSpinner,
    BCardHeader,
    BTabs,
    BTab,
    projectManagement,
    projectManagementTopMan,
    vSelect,
  },
  data() {
    return {
      oriList: [],
      employee: [],
      show: true,
      role: "",
      filter: "",
      searchname: "",
      selectedposition: "",
      allRoles: [],
      rolesList: [
        { text: `${this.$t('Choose Position')}`, value: "", disabled: true },
      ],
      items: [
        // {
        //   name: "",
        //   position: "",
        //   score: "overall score",
        //   email: "",
        //   department_id: "",
        //   employee_number: "",
        //   mobile_number: "",
        // },
      ],
      topManagementView: false,
      selectedEmployeeID: "",
      empIDList: [],
      selectedDept: "",
      DepartmentList: [{ text: `${this.$t('Choose Department')}`, value: "", disabled: true }],
      selectedWork: "",
      WorkList: [{ text: `${this.$t('Choose Work Location')}`, value: "", disabled: true }],
      subsidiary_id: "",
      // department_id: "",
      isFilter: false,
      tempData: null,
      tabNo: this.$route.params.tabIdx !== undefined ? this.$route.params.tabIdx : 0,
    }
  },
  computed: {
    ...mapGetters(['roles', 'userDetails']),
    // filterProducts() {
    //   return this.filteredRole(this.filteredName(this.items))
    // },
  },
  watch: {
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
      this.empIDList = this.empIDList.map(x => {
        if (x.value === ""){
          return {
            ...x,
            text: this.$t('Choose Employee Id'),
          }
        }
        return x
      })
      this.DepartmentList = this.DepartmentList.map(x => {
        if (x.value === ""){
          return {
            ...x,
            text: this.$t('Choose Department'),
          }
        }
        return x
      })
      this.WorkList = this.WorkList.map(x => {
        if (x.value === ""){
          return {
            ...x,
            text: this.$t('Choose Work Location'),
          }
        }
        return x
      })
      this.rolesList = this.rolesList.map(x => {
        if (x.value === ""){
          return {
            ...x,
            text: this.$t('Choose Position'),
          }
        }
        return x
      })
    },
  },
  mounted() {
    // const currentUserRole = localStorage.getItem('userStatus')
    // console.log('user role', this.userDetails)
    // switch (currentUserRole) {
    //   case 'Top Management':
    //     this.topManagementView = true
    //     break
    //   default:
    //     console.log('which role: ', currentUserRole)
    // }
    this.getEmployeeID()
    this.getAllRoles()

    this.getDetails()

    this.getSubsidiary()
    if (this.roles.isTopManagement) {
      this.topManagementView = true
      setTimeout(() => {
        this.getDetails2()
      }, 500)
    }
  },
  methods: {
    getSubsidiary() {
      this.$axios.get(`${this.$baseUrl}/users/current_user`).then(response => {
        this.subsidiary_id = response.data.data.employee.business_unit
        this.getDepartment()
        this.getWorkLocation()
      })
    },
    clearButton() {
      this.selectedposition = ""
      this.searchname = ""
      this.selectedEmployeeID = ""
      this.selectedDept = ""
      this.selectedWork = ""

      this.getDetails()
      this.getDetails2()
      this.isFilter = false
    },
    getEmployeeID() {
      if (this.roles.selectedRole === 3) {
        this.$axios.get(`${this.$baseUrl}/supervisor/getAllEmployee?status=active`).then(response => {
          // console.log(response)
          this.allEmpID = response.data.data
          if (response.data.success){
            this.allEmpID.forEach(empID => {
              this.empIDList.push(empID.employee.employee_number)
              // console.log("hoi role", role.name);
            })
          }
        })
      }
      if (this.roles.selectedRole === 4) {
        this.$axios.get(`${this.$baseUrl}/tm/getAllEmployeeTM?status=active`).then(response => {
          // console.log(response)
          this.allEmpID = response.data.data
          if (response.data.success){
            this.allEmpID.forEach(empID => {
              this.empIDList.push(empID.employee.employee_number)
              // this.employee.push(empID.employee.employee_number)
            })
          }
        })
      }
    },
    getDepartment() {
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.subsidiary_id}`).then(response => {
        this.allDept = response.data.data
        this.DepartmentList = [{ text: `${this.$t('Choose Department')}`, value: "", disabled: true }]
        this.allDept.forEach(b => {
          // this.DepartmentList.push(b.name)
          this.DepartmentList.push({
            value: b.id,
            text: b.name,
          })
        })
      })
    },
    getWorkLocation() {
      const initWorkLocation = [{ text: `${this.$t('Choose Work Location')}`, value: "", disabled: true }]
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.subsidiary_id}`).then(response => {
        this.allWork = response.data.data
        this.WorkList = initWorkLocation
        this.allWork.forEach(b => {
          // this.WorkList.push(b.name)
          this.WorkList.push({
            value: b.id,
            text: b.name,
          })
        })
      })
    },
    getAllRoles() {
      this.$axios.get(`${this.$baseUrl}/position/get_position_by_supervisor`).then(response => {
        this.allRoles = response.data.data

        this.rolesList = [{ text: `${this.$t('Choose Position')}`, value: "", disabled: true }]

        this.allRoles.forEach(role => {
          this.rolesList.push(role.position)
        })
      }).catch(error => {
        // console.log(error.response)
        if (error.response !== undefined || error.response === null || error.response === 'null') {
          if (!error.response.data.success) {
            this.allRoles = []
            this.rolesList = []
          }
        }
      })
    },

    // filteredName(items) {
    //   return items.filter(
    //     name => name.user.name.toLowerCase().includes(this.searchname),
    //   )
    // },
    // filteredRole(items) {
    //   return items.filter(
    //     role => !role.employee.position.indexOf(this.selectedposition),
    //   )
    // },
    filterEmployee(){
      const currentSelectedUser = this.oriList.filter(x => x.employee.employee_number === this.selectedEmployeeID || x.employee.department_id === this.selectedDept || x.employee.branch_id === this.selectedWork || x.employee.position === this.selectedposition || x.employee.full_name === this.searchname)
      // console.log('current user filter', currentSelectedUser)
      this.isFilter = true

      if (this.selectedEmployeeID !== ""){
        if (currentSelectedUser[0] !== undefined){ // kalau takde type error. letak ni
          if (currentSelectedUser[0].type === 'sv'){
            this.getDetails()
            this.items = []
          } else {
            this.getDetails2()
            this.items = []
          }
        } else {
          this.getDetails()
          this.getDetails2()
          this.items = []
        }
      } else {
        // console.log('else')
        this.getDetails()
        this.getDetails2()
        this.items = []
      }
    },
    getDetails() {
      this.$axios.get(`${this.$baseUrl}/supervisor/getAllEmployee?employee_name=${this.searchname}&position=${this.selectedposition}&department_id=${this.selectedDept}&branch_id=${this.selectedWork}&employee_number=${this.selectedEmployeeID}&status=active`).then(response => {
        this.items = response.data.data.map(x => ({
          ...x,
          type: 'sv',
        }))
        // console.log('response', this.items)
        this.show = false
        // console.log("masuk employee", response)
      }).catch(error => {
        this.show = false
        // console.log(error.response)
        if (error.response !== undefined || error.response === null || error.response === 'null') {
          if (!error.response.data.success) {
            // eslint-disable-next-line no-unused-vars
            const emptyDataResponse = error.response.data.message
            // letak toast based on response
            this.items = []
            this.show = false
          }
        }
      })
    },
    getDetails2() {
      this.$axios.get(`${this.$baseUrl}/tm/getEmpSubordinate?employee_name=${this.searchname}&position=${this.selectedposition}&department_id=${this.selectedDept}&branch_id=${this.selectedWork}&employee_number=${this.selectedEmployeeID}&status=active`).then(response => {
        // console.log(response)
        // if (response.data.success === true){
        // this.items2 = response.data.data

        // this.items = _.uniqWith(this.items.concat(response.data.data.map(x => ({
        //   ...x,
        //   type: 'subordinate',
        // }))), _.isEqual)
        const mapSubOrdinate = response.data.data.map(x => ({
          ...x,
          type: 'subordinate',
        }))
        // this.items = []
        this.show = false
        // console.log("masuk subordinate", response)
        // console.log("pilih 2", this.selectedDept)
        if (this.isFilter){
          // this.tempData = _.uniqWith(this.items.concat(response.data.data.map(x => ({
          //   ...x,
          //   type: 'subordinate',
          // }))), _.isEqual)
          let temp = []
          // if (this.items.length !== 0) {
          //   console.log('lepas filter 2', mapSubOrdinate)
          //   temp = this.items.concat(mapSubOrdinate)
          // } else {
          //   console.log('lepas filter 2', mapSubOrdinate)
          //   temp = mapSubOrdinate
          // }
          temp = mapSubOrdinate.concat(this.items)

          this.items = temp
        } else {
          // this.items.push(response.data.data)
          const tempList = this.items.concat(response.data.data)
          this.items = tempList
          this.oriList = _.cloneDeep(tempList)

          // response.data.data.concat(this.items)
          // this.items = response.data.data
          // console.log('items from getdetails', this.items)
        }

        // }
      }).catch(error => {
        // console.log(error.response)
        if (error.response !== undefined || error.response === null || error.response === 'null') {
          if (!error.response.data.success) {
            // eslint-disable-next-line no-unused-vars
            const emptyDataResponse = error.response.data.message
            // letak toast based on response
            this.items = []
            this.show = false
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
